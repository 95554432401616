<template>
  <div class="common-gaine">
    <div class="flex-bet-cen order-header-container">
      <div class="flex-cen">
        <div class="common-plate-title">
          <span>{{ $trans('我的收藏', '我的收藏') }}</span>
        </div>
      </div>
      <div class="flex-cen">
        <label class="flex-cen all-select" for="all-select">
          <input
            type="checkbox"
            class="all-select-input"
            id="all-select"
            v-model="isAllSelect"
          />
          <span class="font-medium">{{ $trans('全选', '全选') }}</span>
        </label>
        <i
          class="iconfont icon-fanhui1"
          :title="$trans('返回上一页', '返回上一页')"
          @click="$router.history.go(-1)"
        ></i>
      </div>
    </div>
    <div class="collection-item-wrap">
      <div
        @click="item.isSelect = !item.isSelect"
        class="collection-item flex"
        v-for="item in data"
        :key="item.id"
      >
        <div
          class="dynamic-wrap flex-bet line-bottom"
          :style="{
            width: 'calc( 100vw - 60px + ' + item.moveWidth / 2 + 'px )',
          }"
        >
          <div class="flex-cen">
            <input
              type="checkbox"
              :id="item.id"
              :checked="item.isSelect"
              v-model="item.isSelect"
            />
            <div class="collection-item-select flex">
              <Img
                :image="item.image"
                :skip="true"
                :id="item.id"
                :styleType="item.styleType"
              />
              <div class="collection-info-wrap">
                <div class="collection-info-title">
                  {{ $trans(`goods-${item.id}.title`, item.title) }}
                </div>
                <div class="collection-info-author">
                  「{{
                    $trans(`artist-${item.artistId}.name`, item.artistName)
                  }}」
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="btn-fill" v-show="isSomeSelect" @click="cancelBatchFavorite">
        {{ $trans('删除', '删除') }}
      </div>
    </div>
  </div>
</template>
<script>
import { loadLocaleMessages } from '@/i18n/i18n'
import Img from '@/components/img/Small.vue'
import { allFavorite, cancelBatchFavorite } from '@/util/js/api.js'

export default {
  name: '',
  data() {
    return {
      data: [], // data
    }
  },
  mounted() {
    this.allFavorite()
  },
  methods: {
    // 获取收藏列表
    allFavorite() {
      allFavorite(this.$store.state.userId)
        .then((res) => {
          this.data = []
          if (res.code == '200') {
            if (!res.data.rows) {
              return
            }
            res.data.rows.forEach((item) => {
              let params = JSON.parse(JSON.stringify(item.shangsfSpu))
              params.collectionId = item.id
              params.artistName = item.artistName
              this.data.push(params)
              if (item.transferList) {
                loadLocaleMessages(
                  item.transferList,
                  `goods-${item.shangsfSpu.id}`
                )
              }
              if (item.artistTransferList) {
                loadLocaleMessages(
                  item.artistTransferList,
                  `artist-${item.shangsfSpu.artistId}`
                )
              }
            })
            this.data = this.data.map((item) => {
              this.$set(item, 'isSelect', false)
              return item
            })
          } else {
            this.$message.error(
              this.$trans(
                '获取列表错误，请稍后再试',
                '获取列表错误，请稍后再试'
              )
            )
          }
        })
        .catch(() => {
          this.$message.error(
            this.$trans('获取列表错误，请稍后再试', '获取列表错误，请稍后再试')
          )
        })
    },
    // 批量取消收藏
    cancelBatchFavorite() {
      let params = {
        favoriteIdList: [],
        userId: this.$store.state.userId,
      }
      this.data.forEach((item) => {
        if (item.isSelect) {
          params.favoriteIdList.push(item.collectionId)
        }
      })
      cancelBatchFavorite(params)
        .then((res) => {
          if (res.code != '200') {
            this.$message.error(
              this.$trans(
                '删除收藏列表错误,请稍后再试',
                '删除收藏列表错误,请稍后再试'
              )
            )
          } else {
            this.$message.success(
              this.$trans('删除收藏列表成功', '删除收藏列表成功')
            )
            this.allFavorite()
          }
        })
        .catch(() => {
          this.$message.error(
            this.$trans('网络错误，请稍后再试', '网络错误，请稍后再试')
          )
        })
    },
  },
  computed: {
    isAllSelect: {
      // 全选
      get() {
        return this.data.every((item) => {
          return item.isSelect
        })
      },
      set(val) {
        this.data.map((item) => {
          item.isSelect = val
        })
      },
    },
    isSomeSelect() {
      return this.data.some((item) => {
        return item.isSelect
      })
    },
  },
  components: {
    Img,
  },
}
</script>
<style scoped>
.collection-title {
  height: 68px;
}
.collection-title h2 {
  font-family: 'myCoolFontMedium';
  font-size: 16px;
  font-weight: bold;
}
.collection-all-select {
  font-family: 'myCoolFontMedium';
  font-size: 12px;
  color: #999999;
  cursor: pointer;
}
.collection-all-select span {
  margin-left: 10px;
}
.collection-item-wrap {
  overflow: hidden;
  padding: 0 30px;
  border: 1px solid rgba(17, 17, 17, 0.1);
  margin-top: 50px;
}
.collection-item {
  position: relative;
  cursor: pointer;
}
.dynamic-wrap {
  padding: 30px 0 23px 6px;
  align-items: flex-end;
  transition: width 0.5s;
  width: 100%;
}
.collection-item-select {
  margin-left: 11px;
}
.collection-info-wrap {
  margin-left: 18px;
}
.collection-info-title {
  font-family: 'myCoolFontMedium';
  font-size: 14px;
  margin-bottom: 10px;
}
.collection-info-author {
  color: #595959;
  font-size: 12px;
  margin-left: -5px;
}
.fixed-bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  height: 50px;
  background: #ffffff;
  width: 100vw;
  overflow-x: scroll;
  padding: 0 25px;
}
.btn {
  height: 24px;
  border-radius: 12px;
  padding: 0 20px;
  text-align: center;
  line-height: 24px;
  font-size: 12px;
  margin-left: 10px;
  cursor: pointer;
}
.btn:first-child {
  margin-left: 0;
}
.all-select {
  cursor: pointer;
}
.all-select input {
  margin-right: 5px;
}
.btn-fill {
  cursor: pointer;
  margin: 50px auto;
  width: 400px;
}
</style>
<style>
.collection-container .img__gaine-container {
  width: auto;
}
</style>
